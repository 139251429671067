<template>
  <b-card>
    <h3>
      {{ $t('student-report-module.study-time') }}
    </h3>
    <apexchart :options="chartOptions"
               :series="chartSeries"
               type="bar"
               height="350"
    />
  </b-card>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import VueApexCharts from 'vue-apexcharts'
import { BCard } from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      categories: [],
      chartSeries: [],
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          // colors: ['#008FFB', '#00E396'],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          title: {
            text: i18n.tc('student-report-module.time-in-minutes'),
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return val
            },
          },
        },
      }
    },
  },
  mounted() {
    this.getStudyTime()
  },
  methods: {
    getSeriesColor(type) {
      switch (type) {
        case 'homework':
          return '#00E396'
        case 'follow_up':
          return '#008FFB'
        default:
          return ''
      }
    },
    getSeriesName(name) {
      if (name === 'homework') {
        return 'Preview'
      }
      return 'Review'
    },
    getStudyTime() {
      useJwt.getIndividualUserStudyTimeReport({
        classId: this.classId,
        userId: this.userId,
      }).then(response => {
        const { result } = response.data.data
        const categories = [...new Set(result.map(item => item.date_attempt))]
        const series = [...new Set(result.map(item => item.assignment_type))]
        const chatSeriesData = []
        series.forEach(name => {
          const data = []
          categories.forEach(cat => {
            const value = result.find(i => i.assignment_type === name && i.date_attempt === cat)
            data.push(+value?.total_minutes || 0)
          })
          chatSeriesData.push({
            name: this.getSeriesName(name),
            data,
            color: this.getSeriesColor(name),
          })
        })
        this.chartSeries = chatSeriesData
        this.categories = categories
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>
